$.fn.serializeObject = function () {
    const o = {};
    const a = this.serializeArray();
    $.each(a, function () {
        if (o[this.name] !== undefined) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

/**
 * @class smForm
 * A form handler built on top of jquery
 * Validates input on keyup, sets and handles custom html attributes
 *
 * @param {Object} options
 * @param {String} options.selector - the DOM selector
 * @callback {Function} options.submitCallback - the form submitCallback
 * @param {String} options.submitCallback
 */
const smForm = function (options) {
    window.form = $(options.selector);

    if (window.form) {
        window.groups = window.form.find('.sm-form__group');
        window.inputs = window.form.find('.sm-form__input');
        window.submit = window.form.find('.sm-form__submit');
        window.passwordInputs = window.form.find('.sm-form__input--password');
        window.passwordToggle = window.form.find('.sm-form__password-toggle');
        window.inputControls = ['passes-validation', 'passes-required', 'touched', 'dirty'];

        // special handling for hybrid angular-smForm forms
        window.disableSubmitHandling = options.disableSubmitHandling;

        self = window;
        $.each(window.inputs, (index, element) => {
            self.inputControls.forEach(value => {
                $(element).attr(value, false);
            });
        });

        /**
         * Validates if an input is required and has a value
         *
         * @param {jquery wrapped element} input
         * @returns boolean
         */
        const validateRequired = input => {
            value = $(input).val();
            if ($(input).is('[required]') && !value) {
                return false;
            }
            return true;
        };
        this.validateRequired = validateRequired;

        /**
         * Validates an input value against it's pattern attribute
         *
         * @param {jquery wrapped element} input
         * @returns bool
         */
        const validatePattern = input => {
            value = $(input).val();
            const pattern = new RegExp(eval($(input).attr('pattern')));
            return !!value.match(pattern);
        };
        this.validatePattern = validatePattern;

        /**
         * updateErrorState
         * Sets the input error class
         * We only show the error when an input is invalid and has been focused out (which we define as the 'touched' input attribute)
         * @param {any} input
         */
        const updateErrorState = input => {
            if (!input.is('[novalidate]')) {
                if (input.attr('passes-validation') === 'true' && input.attr('passes-required') === 'true') {
                    input.parent().removeClass('sm-form__group--error');
                } else if (input.attr('touched') === 'true') {
                    input.parent().addClass('sm-form__group--error');

                    if (input.attr('passes-required') === 'false') {
                        input.nextAll('.sm-form__error-message').text(input.attr('error-required'));
                    } else if (input.attr('passes-validation') === 'false') {
                        input.nextAll('.sm-form__error-message').text(input.attr('error'));
                    }
                }
            }
        };
        this.updateErrorState = updateErrorState;

        /**
         * Checks if all inputs are valid.
         * mutates the disabled attribute of the submit button
         *
         */
        const updateSubmitButton = () => {
            // In some cases (at least) we handle this in angular
            if (self.disableSubmitHandling) {
                return;
            }
            const valid = self.inputs.toArray().reduce((accumulator, element) => {
                let passesRequired;
                let passesValidation;

                if ($(element).is('[required]')) {
                    passesRequired = $(element).attr('passes-required') === 'true';
                } else {
                    passesRequired = true;
                }

                if ($(element).is('[pattern]')) {
                    passesValidation = $(element).attr('passes-validation') === 'true';
                } else {
                    passesValidation = true;
                }

                const valid = passesRequired && passesValidation;
                return accumulator && valid;
            }, true);
            self.valid = valid;
            if (valid) {
                self.submit.attr('disabled', false);
            } else {
                self.submit.attr('disabled', true);
            }
        };
        this.updateSubmitButton = updateSubmitButton;

        /**
         * keyUp
         * Validates the input pattern and sets the input passes-validation attribute
         * Validates the input value if required and sets the input passes-required attribute
         * Sets the input dirty attribute to true
         * Updates the input error state
         * Updates the submit button
         * @param {Event} event
         */
        const inputChange = function (event) {
            const input = $(event.target);
            input.attr('passes-validation', validatePattern(input));
            input.attr('passes-required', validateRequired(input));
            input.attr('dirty', true);
            updateErrorState(input);
            updateSubmitButton.call(this);
        };
        this.inputChange = inputChange;

        /**
         * blur
         * Sets the input touched attribute
         * Updates the input error state
         * @param {Event} event
         */
        const blur = event => {
            const input = $(event.target);
            input.attr('touched', true);
            updateErrorState(input);
        };
        this.blur = blur;

        /**
         * onFormError
         * Re-enables the inputs and the submit button
         * Sets the initial submit button text
         * Attaches an error message to the bottom of the form
         * Adds error class to specified inputs
         *
         * @param {Object} error
         * @param {String} error.message
         * @param {Array} error.invalidFields
         */
        const onFormError = error => {
            const inputs = self.inputs;
            self.submit.text(self.submit.attr('text'));
            $.each(inputs, (index, element) => {
                $(element).attr('readonly', false);
            });

            // remove existing error messages
            $('.sm-form__error-message').text('');

            if (error.message) {
                self.submit.parent('.sm-form__group').addClass('sm-form__group--error');
                self.submit.next().text(error.message);
            } else {
                self.submit.parent('.sm-form__group').removeClass('sm-form__group--error');
                self.submit.next().text();
            }
            if (error.invalidFields) {
                error.invalidFields.forEach(field => {
                    $.each(inputs, (index, element) => {
                        if ($(element).attr('name') === field) {
                            $($(element).parent()).addClass('sm-form__group--error');
                        }
                    });
                });
            }
        };
        this.onFormError = onFormError;

        /**
         * onSubmit
         * Disables the form inputs
         * Disables the form submit button
         * Sets the form submit button loading text
         * Calls the submitCallback
         * @param {Event} event
         */
        const onSubmit = event => {
            event.preventDefault();
            self.submit.attr('disabled', true);
            self.submit.parent('.sm-form__group').removeClass('sm-form__group--error');

            $.each(inputs, (index, element) => {
                $(element).attr('readonly', true);
            });

            if (self.submit.attr('loading-text')) {
                self.submit.text(self.submit.attr('loading-text'));
            }
            if (options.submitCallback) {
                options.submitCallback($(event.target).serializeObject(), onFormError.bind(self));
            }
        };
        this.onSubmit = onSubmit;

        /**
         * togglePasswordSwitcher
         * Toggles the password switcher visiblity.
         * @param {any} event
         */
        const togglePasswordSwitcher = event => {
            $(event.target).next('.sm-form__password-toggle').toggle();
        };

        /**
         * passwordToggleClick
         * Toggles the password input type attribute from 'password' to 'text' and so forth.
         * Focuses the input
         * @param {any} event
         */
        const passwordToggleClick = event => {
            event.preventDefault();
            event.stopPropagation();
            const input = $(event.target).prev('.sm-form__input');
            if (input.attr('type') === 'password') {
                input.attr('type', 'text');
            } else {
                input.attr('type', 'password');
            }

            // Place the cursor at end of text in input
            input.val(input.val());
        };
        this.passwordToggleClick = passwordToggleClick;

        // inputs.keyup(inputChange);
        window.inputs.bind('change keyup', inputChange.bind(window));
        window.inputs.blur(blur.bind(window));
        window.form.submit(onSubmit.bind(window));

        window.passwordInputs.focus(togglePasswordSwitcher.bind(window));
        window.passwordInputs.blur(togglePasswordSwitcher.bind(window));
        window.passwordToggle.mousedown(passwordToggleClick.bind(window));

        // added after handoff from pixelmatters in order to handle oauth errors being
        // pulled from search params
        window.onFormError = onFormError;
    }
};

module.exports = smForm;
