import { targetBrand } from './targetBrand';
import { Brand, type BrandNameProp, type Config, type UserOrDomainOrBrand } from './AppBranding.types';
import fetchBrandConfig from './fetchBrandConfig';

/*
 *  Returns the formatted brand name for the given `user`. If no `user` is found,
 *  but a `config` has been passed in, the `config` will be used to determine what
 *  brand name should be returned.
 *  @param `format` - The format of the returned brand name. Supported values are
 *      `long`, `standard`, and `short`.
 *  @param `branding` - The target brand name. Supported values are
 *      `quantic`, `valar`, `smartly`, and `miyamiya`. Defaults to `quantic`.
 */
export function formattedBrandName(format: string, branding: Brand = Brand.quantic): string {
    const prop = `brandName${format.charAt(0).toUpperCase()}${format.slice(1)}` as BrandNameProp;
    return fetchBrandConfig(branding)[prop];
}

/*
 *  Returns the formatted brand name for the given `user`. If no `user` is found,
 *  but a `config` has been passed in, the `config` will be used to determine what
 *  brand name should be returned.
 *  @param `config` - App config that will be used as a fallback to determine the
 *      appropriate brand name value if no `user` is passed in.
 *  @param `format` - The format of the returned brand name. Supported values are
 *      `long`, `standard`, and `short`.
 */
export function getBrandName(
    userOrDomainOrBrand: UserOrDomainOrBrand,
    config: Config | undefined = undefined,
    format = 'short',
) {
    const brand = targetBrand(userOrDomainOrBrand, config);
    return formattedBrandName(format, brand);
}
