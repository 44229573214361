import { gsap } from 'FrontRoyalGsap';

/*
 *  Navigation functions
 */
(() => {
    const selector = '.nav-menu-toggler';
    const cssClass = 'nav-menu__list-wrapper--is-open';
    let parent;

    $(selector).on('click', function () {
        parent = $(this).parent();

        if (parent.hasClass(cssClass)) {
            parent.removeClass(cssClass);
        } else {
            $(selector).parent().addClass(cssClass).not(parent).removeClass(cssClass);
        }
    });

    // close menu on blur
    $('body').on('click', evt => {
        if (parent && !$.contains(parent.get(0), evt.target)) {
            $(selector).parent().removeClass(cssClass);
        }
    });

    // Close all menus on scroll/hashchange
    $(window).on('scroll hashchange', () => {
        if ($(selector).parent().hasClass(cssClass)) {
            $(selector).parent().removeClass(cssClass);
        }
    });

    let nestedTimeout = null;
    $('.nav-menu__nested').hover(
        function handlerIn() {
            clearTimeout(nestedTimeout);
            $(this).addClass('active');
        },
        function handlerOut() {
            const self = this;
            nestedTimeout = setTimeout(() => {
                $(self).removeClass('active');
            }, 200);
        },
    );
    $('.nav-menu__nested-menu').hover(
        function handlerIn() {
            clearTimeout(nestedTimeout);
        },
        function handlerOut() {
            const self = this;
            nestedTimeout = setTimeout(() => {
                $(self).parent().removeClass('active');
            }, 200);
        },
    );

    $('.nav-menu-mobile__nested').click(function () {
        $(this).toggleClass('active');
        $(this).next('.nav-menu-mobile__nested-menu').toggleClass('active');
    });
})();

/*
 *  Top/sticky header functions
 */
(() => {
    const header = $('.header');
    const headerTop = $('.header__wrapper--top');
    const headerSticky = $('.header__wrapper--sticky');
    const wrapper = headerTop.find('.nav-menu-mobile__toggler-wrapper');
    const openBtn = headerTop.find('.nav-menu-mobile__toggler-btn--open');
    const closeBtn = headerTop.find('.nav-menu-mobile__toggler-btn--close');
    const wrapperSticky = headerSticky.find('.nav-menu-mobile__toggler-wrapper');
    const openStickyBtn = headerSticky.find('.nav-menu-mobile__toggler-btn--open-sticky');
    const closeStickyBtn = headerSticky.find('.nav-menu-mobile__toggler-btn--close-sticky');
    // const signStickyBtn = headerSticky.find('.button--sm-primary');
    const cta = $('.js-cta');
    const openClass = 'header--is-open';
    const docElem = $(document);
    let didScroll = false;
    let headerLimitIsPassed = false;
    const dropHeaderOn = 52;
    const openBP = 991;
    let canAnimate = true;
    let shouldRemoveIsDown = false;
    let bodyPosition = 0;

    const openMenuTL = gsap.timeline({
        paused: true,
        onStart() {
            canAnimate = false;

            header.addClass(openClass);
            bodyPosition = $(window).scrollTop();

            $('html, body').addClass('scroll-freeze');
            $(document.body).css('top', `-${bodyPosition}px`);
        },
        onComplete() {
            canAnimate = true;
        },
    });

    const closeMenuTL = gsap.timeline({
        paused: true,
        onComplete() {
            $('html, body').removeClass('scroll-freeze');
            $(window).scrollTop(bodyPosition);
            $(document.body).css('top', '');
            canAnimate = true;
        },
    });

    function openMenu(wrapperType) {
        if (!headerLimitIsPassed) {
            headerSticky.addClass('is-down');
            shouldRemoveIsDown = true;
        }
        const signUpBtn = wrapperType.find('.button');
        const signInBtn = wrapperType.find('.link');
        const menuItems = wrapperType.find('li');

        openMenuTL.clear();

        openMenuTL
            .set(menuItems, {
                opacity: 0,
            })
            .set(signUpBtn, {
                opacity: 0,
            })
            .set(signInBtn, {
                opacity: 0,
            })
            .to(
                wrapperType,
                {
                    duration: 0.2,
                    display: 'block',
                    opacity: 1,
                    top: '100%',
                    ease: 'power2.inOut',
                },
                '+=0.23',
            )
            .to($('.js-open-mobile-menu'), {
                duration: 0,
                display: 'none',
                opacity: 0,
            })
            .to($('.js-close-mobile-menu'), {
                duration: 0,
                display: 'block',
                opacity: 1,
            })
            .to(
                signUpBtn,
                {
                    duration: 0.15,
                    opacity: 1,
                    ease: 'power2.in',
                },
                '-=0.15',
            )
            .to(menuItems, {
                duration: 0.1,
                opacity: 1,
                ease: 'power2.inOut',
                stagger: 0.05,
            })
            .to(
                signInBtn,
                {
                    duration: 0.1,
                    opacity: 1,
                    ease: 'power2.in',
                },
                '-=0.1',
            );

        openMenuTL.play();
    }

    function closeMenu(wrapperType) {
        if (!headerLimitIsPassed && shouldRemoveIsDown) {
            headerSticky.removeClass('is-down');
            shouldRemoveIsDown = false;
        }

        header.removeClass(openClass);

        headerSticky.removeAttr('style');
        closeMenuTL.clear();
        closeMenuTL
            .to(wrapperType, {
                duration: 0.44,
                display: 'none',
                opacity: 0,
                top: '-100px',
                ease: 'power2.inOut',
            })
            .to(
                $('.js-close-mobile-menu'),
                {
                    duration: 0.2,
                    display: 'none',
                    opacity: 0,
                },
                '-=0.5',
            )
            .to(
                $('.js-open-mobile-menu'),
                {
                    duration: 0.2,
                    display: 'block',
                    opacity: 1,
                },
                '-=0.2',
            );
        closeMenuTL.play();
    }

    // Call header events checker on scroll
    $(window).scroll(() => {
        if (!didScroll) {
            didScroll = true;
            setTimeout(scrollPage, 0);
        }
    });

    // Get scroll Y position
    function scrollY() {
        return window.pageYOffset || docElem.scrollTop();
    }

    // Prevent page scroll when body has class freezed
    $('body').on('touchmove', event => {
        if (isBodyFreezed()) {
            event.preventDefault();
        }
    });

    // Enable menu links scroll while preventing page scroll
    $('.scroll-pane').on('touchstart touchmove', event => {
        if (isBodyFreezed() && !isMenuFullyVisible()) {
            event.stopImmediatePropagation();
        }
    });

    // enable touch events on header when scroll is finished (ios safari fix)
    $('.scroll-pane').on('touchend', event => {
        if (isBodyFreezed() && !isMenuFullyVisible()) {
            const refEvent = event.originalEvent;

            if (event.target.dispatchEvent) {
                event.target.dispatchEvent(refEvent);
            } else if (event.target.fireEvent) {
                event.target.fireEvent(refEvent);
            }
        }
    });

    function isBodyFreezed() {
        return $('body').hasClass('scroll-freeze');
    }

    function isMenuFullyVisible() {
        const winH = window.innerHeight;
        const menuH = 610;

        return winH >= menuH;
    }

    // Header events checker on scroll
    function scrollPage() {
        const contentTabs = $('.hero__content .content-tabs');
        const noStickyMenu = $('body').hasClass('no-sticky-menu');
        if (noStickyMenu && contentTabs.length > 0) {
            const tabsLimitPassed = scrollY() >= contentTabs.offset().top;

            if (canAnimate && !isBodyFreezed()) {
                if (tabsLimitPassed) {
                    $('.tabs-header').addClass('tabs-header--active');
                } else {
                    $('.tabs-header').removeClass('tabs-header--active');
                }
            }
        } else if (!noStickyMenu && canAnimate && !isBodyFreezed()) {
            headerLimitIsPassed = scrollY() >= dropHeaderOn;

            if (headerLimitIsPassed) {
                headerSticky.addClass('is-down');
                cta.addClass('button--sm-secondary').removeClass('button--sm-primary');
            } else {
                headerSticky.removeClass('is-down');
                cta.addClass('button--sm-primary').removeClass('button--sm-secondary');
            }
        }

        didScroll = false;
    }

    // Close menu and add/remove classes on window resize
    $(window).on('load resize', () => {
        const vw = $(window).width();

        if (vw > openBP) {
            // signStickyBtn.addClass('button--sm-secondary').removeClass('button--sm-primary');

            if (header.hasClass(openClass) && canAnimate) {
                closeMenu(wrapperSticky);
                closeMenu(wrapper);
            }
        }
    });

    // Click and touch binding to improve mobile accessibility
    openBtn.on('click touchstart touchend', event => {
        // event.preventDefault();
        event.stopPropagation();
        if (!header.hasClass(openClass) && canAnimate) {
            openMenu(wrapper);
        }
    });

    openStickyBtn.on('click touchstart touchend', event => {
        // event.preventDefault();
        event.stopPropagation();
        if (!header.hasClass(openClass) && canAnimate) {
            openMenu(wrapperSticky);
        }
    });

    closeBtn.on('click touchend', () => {
        if (header.hasClass(openClass) && canAnimate) {
            closeMenu(wrapper);
        }
    });

    closeStickyBtn.on('click touchend', () => {
        if (header.hasClass(openClass) && canAnimate) {
            closeMenu(wrapperSticky);
        }
    });

    if (scrollY() >= dropHeaderOn && !$('body').hasClass('no-sticky-menu')) {
        headerSticky.addClass('is-down');
        cta.addClass('button--sm-secondary').removeClass('button--sm-primary');
    }
})();
