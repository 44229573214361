// Cache root element to increase performance
const $rootElement = $('html, body');

// Angular intercepts any # (hash) URLs and converts them to #/ paths
// This is because we have not configured the marketing Angular app to be html5mode
// We can't do that, because it would then intercept all normal URLs and try to route
// them within the angular app, instead of reloading the page.
// Rather than refactor the entire marketing site, for expediency we've decided to
// manually intercept the hash URLs and attempt to scroll directly to the named
// element with some extra offset for the header and banner.
if (window.location.hash) {
    // hack: remove slash that angular adds
    const hash = window.location.hash.replace('#/', '#');
    let offset;
    try {
        offset = $(hash).offset();
    } catch (e) {
        // noop
    }
    if (offset) {
        $rootElement.scrollTop(offset.top - 140); // extra space for the header and banner
    }
}
