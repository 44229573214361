import angularModule from 'AngularHttpQueueAndRetry/angularModule/scripts/angular_http_queue_and_retry';
import { generateGuid } from 'guid';
import wrapAngularHttpTimeout from 'wrapAngularHttpTimeout';

/*
    The HttpQueue actually keeps the list of queued request configs
    and handles deciding when an error should result in a retry.

    What it does.

    1. When a request hits HttpQueue, if nothing else is in the queue, it will be sent.
    2. If requests are in the queue, then each one will be sent one after the other.  The
       next request in the queue will not be sent until the previous one returns successfully.
    3. If a request fails with one of the retryable status codes (see below), it will be sent
       again once.
    4. If a request fails twice, or fails with a status code other than the retryable ones,
       then:

        1. The queue will be frozen.  No further requests can be sent through the queue. The
           one exception is that the request that failed can be retried with
           the HttpQueue.retry() public method (This can be useful for, for example,
           offline and unauthorized errors. In those cases it's possible to get back
           online or log back in and retry the request again.)
        2. The original promise will be rejected, allowing the initial requester to handle
           the error.
*/
angularModule.factory('HttpQueue', [
    '$injector',
    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const $q = $injector.get('$q');
        const $timeout = $injector.get('$timeout');
        let EventLogger;
        let Event;
        const Singleton = $injector.get('Singleton');
        const HttpQueueAndRetry = $injector.get('HttpQueueAndRetry');

        const HttpQueue = SuperModel.subclass(function () {
            this.include(Singleton);
            this.defineSingletonProperty(
                'queue',
                'onResponseSuccess',
                'onResponseError',
                'retry',
                'unfreezeAfterError',
                'reset',
                'blockedByFailedRequest',
            );

            this.createInstance = () => {
                // no idea why I have to lazy load this module
                EventLogger = $injector.get('EventLogger');
                Event = $injector.get('EventLogger.Event');
                return new HttpQueue();
            };

            Object.defineProperty(this.prototype, '_hasPendingRequest', {
                get() {
                    return !!this._pendingEntry;
                },
            });

            Object.defineProperty(this.prototype, '_pendingRequestQueueId', {
                get() {
                    return this._pendingEntry && this._pendingEntry.config.httpQueue.queueId;
                },
            });

            Object.defineProperty(this.prototype, '_blocked', {
                get() {
                    // If there is a request that has not yet returned, then
                    // we are blocked from sending another.
                    if (this._hasPendingRequest) {
                        return true;
                    }

                    // If a request failed, then we are blocked from sending
                    // any request other than the one that failed.
                    if (this._lastFailedQueueId) {
                        const nextEntry = this._queue[0];
                        if (nextEntry.config.httpQueue.queueId !== this._lastFailedQueueId) {
                            return true;
                        }
                    }

                    return false;
                },
            });

            Object.defineProperty(this.prototype, 'blockedByFailedRequest', {
                get() {
                    return !!this._lastFailedQueueId;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'loggingVerbosity', {
                value: 'errors', // can be silent|errors|verbose
                writable: true,
            });

            return {
                maxAttemptCount: 2,

                statusesToRetry: [
                    0, // canceled
                    403, // cloudflare issue? https://app.getsentry.com/pedago/staging-front-royal/issues/100938092/events/4186434619/
                    408, // timeout
                    429, // too many requests
                    502, // bad gateway (cloudflare / nginx)
                    503, // server unavailable
                    504, // gateway timeout (cloudflare)
                    520, // cloudflare Web server is returning an unknown error. See https://sentry.io/pedago/front-royal/issues/346184137/
                    521, // webs server is down for cloudflare
                    522, // connection timed out for cloudflare
                    524, // cloudflare timed out
                    530, // cloudflare could not resolve DNS to find our origin IP. See https://sentry.io/pedago/front-royal/issues/353792596/
                    // maybe we should do 409 (conflict) as well?
                ],

                initialize() {
                    this._queue = [];
                    this._pendingEntry = undefined;
                    this._lastFailedQueueId = undefined;
                    this._storedEvents = {};

                    // In chrome, if there is a pending request when the user goes offline, it
                    // will never succeed or fail.  We will force it to fail.
                    // Since we started adding timeouts to requests, we think this
                    // is maybe not necessary, but keeping the code around for now.  The issue
                    // is, is it better to warn someone fast when they've gone offline, or
                    // better to wait in the hopes they come back online.  Also, the code to
                    // make this work is a little more complex now that we're passing the
                    // other timeout.
                    // $rootScope.$on('offline', this._cancelPendingRequest.bind(this));
                },

                queue(requestConfig) {
                    this._checkIfSomeoneElseIsRetrying(requestConfig);
                    const entry = this._getQueueEntry(requestConfig);

                    const queueEvent = this._log('http_queue:queue_request', requestConfig);
                    this._storedEvents[entry.config.httpQueue.queueId] = {
                        queue_request: queueEvent,
                    };

                    // put retries at the beginning of the list, new
                    // requests behind any requests of equal or higher priority
                    if (requestConfig.httpQueue.retry) {
                        this._queue.unshift(entry);
                    } else {
                        const priority = entry?.config?.httpQueueOptions?.priority || 0;
                        const pos = this._queue.findIndex(
                            existingEntry => existingEntry?.config?.httpQueueOptions?.priority < priority,
                        );
                        if (pos === -1) {
                            this._queue.push(entry);
                        } else {
                            this._queue.splice(pos, 0, entry);
                        }
                    }

                    this._shiftIfNoRequestPending();

                    // since this is being called by a request interceptor, when this
                    // promise resolves, $http will send the request
                    return entry.promise;
                },

                onResponseSuccess(response) {
                    if (this._isResponseForPendingRequest(response)) {
                        const requestQueuedEvents = this._storedEvents[response.config.httpQueue.queueId];
                        const successEvent = this._log('http_queue:request_success', response.config);

                        // add in information about how long it took the request to
                        // return
                        if (requestQueuedEvents) {
                            successEvent.addDurationInfo(requestQueuedEvents.queue_request, 'since_request_queued');
                            successEvent.addDurationInfo(requestQueuedEvents.send_request, 'since_request_sent');
                        }
                        if (this._isConfigForLastFailedRequest(response.config)) {
                            this._lastFailedQueueId = undefined;
                        }
                        this._pendingEntry = undefined;
                        this._shiftIfNoRequestPending();
                    }
                    return response;
                },

                onResponseError(response) {
                    if (!response || !response.config) {
                        return $q.reject(response);
                    }

                    if (response.config.httpQueue && response.config.httpQueue.canceled) {
                        return $q(() => {}); // let it die
                    }

                    if (this._isResponseForPendingRequest(response)) {
                        // _lastFailedRequest is just used for logging in debug mode
                        this._lastFailedRequest = this._pendingEntry;
                        this._pendingEntry = undefined;
                        this._lastFailedQueueId = response.config.httpQueue.queueId;

                        // If a request fails, then we're no longer interested in how
                        // long it took when it finally resolves.  These would be outliers
                        // that can't be analyzed along with the normal cases
                        delete this._storedEvents[response.config.httpQueue.queueId];

                        const shouldRetry = this._shouldRetry(response);
                        this._log('http_queue:request_error', response.config, {
                            status: response.status,
                            should_retry: shouldRetry,
                        });

                        if (shouldRetry) {
                            return this.retry(response.config);
                        }
                    }

                    // returning a rejected promise will ensure that the error
                    // gets handled normally by whoever originally made the request
                    return $q.reject(response);
                },

                retry(config) {
                    this._log('http_queue:retry', config);

                    const queueId = config.httpQueue && config.httpQueue.queueId;
                    if (!queueId || queueId !== this._lastFailedQueueId) {
                        throw new Error('Can only retry the last request that failed.');
                    }
                    config.httpQueue.retry = true;

                    // the request interceptor will push this request onto the front of the queue
                    const $http = $injector.get('$http');
                    return $http(config);
                },

                unfreezeAfterError(config) {
                    if (this._isConfigForLastFailedRequest(config)) {
                        this._lastFailedQueueId = undefined;
                        this._shiftIfNoRequestPending();
                    }

                    // If this request was queued and failed, then it shuold be the last failed request. Warn
                    // if that isn't the case. But if we never queued it in the first place, then there's nothing
                    // we need to do here.
                    else if (HttpQueueAndRetry.shouldQueue(config)) {
                        const err = new Error('Unexpected config.');
                        err.extra = {
                            lastFailedQueueId: this._lastFailedQueueId || null,
                            config: config || null,
                            queue: this._queue || null,
                        };
                        throw err;
                    }
                },

                reset() {
                    // NOTE: maybe we should be calling entry.cancel() on things in
                    // the queue as well?  Right now, those promises are just hanging
                    // forever.  If we called cancel, they would be rejected.
                    this._cancelPendingRequest();
                    this.initialize();
                },

                _shouldRetry(failedResponse) {
                    return (
                        this.statusesToRetry.includes(failedResponse.status) &&
                        failedResponse.config.httpQueue.attemptCount < this.maxAttemptCount &&
                        !failedResponse.config?.httpQueueOptions?.disable_retry
                    );
                },

                _isResponseForPendingRequest(response) {
                    const httpQueueConfig = response.config.httpQueue || {};
                    return httpQueueConfig.queueId && httpQueueConfig.queueId === this._pendingRequestQueueId;
                },

                _isConfigForLastFailedRequest(config) {
                    const httpQueueConfig = (config && config.httpQueue) || {};
                    return httpQueueConfig.queueId && httpQueueConfig.queueId === this._lastFailedQueueId;
                },

                // If something external to http queue catches an error
                // in a responseInterceptor and retries the request, we should
                // immediately let it through as though it were a retry initiated
                // by HttpQueue.  For an example of this see FrontRoyalStore's
                // responseInterceptor, or the relevant spec in the 'queue' block
                // of the HttpQueue specs.
                _checkIfSomeoneElseIsRetrying(requestConfig) {
                    const httpQueueConfig = requestConfig.httpQueue;
                    if (this._pendingRequestQueueId && httpQueueConfig?.queueId === this._pendingRequestQueueId) {
                        httpQueueConfig.retry = true;
                        this._pendingEntry = undefined;
                    }
                },

                _getQueueEntry(requestConfig) {
                    // tweak originating config to provide additional details about queue sending / id
                    const entry = {
                        config: requestConfig,
                    };
                    requestConfig.httpQueue = requestConfig.httpQueue || {
                        queueId: generateGuid(),
                    };

                    requestConfig.httpQueue.initialSendAt = requestConfig.httpQueue.initialSendAt || new Date();
                    requestConfig.httpQueue.initialSendAtNow = Date.now();

                    // since this is being called by a request interceptor, when this
                    // promise resolves, $http will send the request
                    entry.promise = $q((sendHttpRequest, cancelRequestInterceptorChain) => {
                        // Setup the cancel behavior that will be used while this
                        // entry is still in the queue.  (This is replaced below once
                        // the request is in flight)
                        entry.cancel = function (...args) {
                            cancelRequestInterceptorChain.apply(this, args);
                        };

                        entry.resolve = function (...args) {
                            // When the entry is resolved, the request is sent to $http. After
                            // that point, if we want to cancel it, we have to resolve a promise
                            // that was passed to $http via the `timeout` property.  So, we
                            // create an `entry.cancel` method that will do that.  We also need
                            // wrapAngularHttpTimeout in case there is already a `timeout` value
                            // in this config.
                            wrapAngularHttpTimeout(
                                requestConfig,
                                $q(cancelHttpRequest => {
                                    entry.cancel = () => {
                                        entry.config.httpQueue.canceled = true;
                                        cancelHttpRequest();
                                    };
                                }),
                                $injector,
                            );
                            sendHttpRequest.apply(this, args);
                        };
                    });

                    return entry;
                },

                // separate method that can be mocked in tests
                _msSince(timestamp) {
                    return Date.now() - timestamp;
                },

                _shiftIfNoRequestPending() {
                    if (this._queue.length === 0) {
                        return;
                    }

                    // In devmode, log when the queue is blocked for a while
                    if (this._blocked && !this._blockedTimeout) {
                        const ConfigFactory = $injector.get('ConfigFactory');
                        const appConfig = ConfigFactory.getSync(true);
                        if (appConfig?.appEnvType() === 'development') {
                            this._blockedTimeout = $timeout(() => {
                                if (this._blocked) {
                                    let message;
                                    let details;
                                    if (this._pendingEntry) {
                                        message = 'There is a pending request: ';
                                        details = this._pendingEntry;
                                    } else {
                                        message = 'Last request failed:';
                                        details = this._lastFailedRequest;
                                    }
                                    console.log(`HttpQueue blocked for 5+ seconds. ${message}`, details);
                                }
                            }, 5000);
                        }
                    }

                    if (!this._blocked) {
                        // _blockedTimeout id just related debug to logging in devmode
                        $timeout.cancel(this._blockedTimeout);
                        this._blockedTimeout = null;

                        const entry = this._queue.shift();
                        const config = entry.config;

                        config.httpQueue.attemptCount = config.httpQueue.attemptCount
                            ? config.httpQueue.attemptCount + 1
                            : 1;

                        // Eventually GET requests, which
                        // have no data, should also support sending this info, but we don't need this
                        // now, so we only do it if there is data.  (Right now we only use queued_for_seconds
                        // in event_bundle_controller, and retry for testing purposes.)
                        //
                        // Also, things that are added to the config should probably configurable
                        // kind of like how addFilter allows you to decide which requests to queue, but
                        // we don't need that now either.
                        if (config.data) {
                            config.data.http_queue = {
                                queued_for_seconds: this._msSince(config.httpQueue.initialSendAtNow) / 1000,
                                retry: config.httpQueue.retry || false,
                                attempt_count: config.httpQueue.attemptCount,
                            };
                        }
                        this._pendingEntry = entry;
                        this._lastFailedRequest = null;

                        // if this request was previous canceled, it no longer is
                        entry.config.httpQueue.canceled = false;

                        const sendEvent = this._log('http_queue:send_request', config);

                        this._storedEvents[entry.config.httpQueue.queueId].send_request = sendEvent;

                        entry.resolve(config);
                    }
                },

                _cancelPendingRequest() {
                    if (this._pendingEntry && this._pendingEntry.cancel) {
                        this._pendingEntry.cancel();
                    }
                },

                _log(eventType, requestConfig, extra) {
                    // we need to be careful because stuff can get recursive
                    let payload = extra || {};
                    payload = angular.extend(
                        {
                            request_config: {
                                url: requestConfig.url,
                                method: requestConfig.method,
                                httpQueue: requestConfig.httpQueue,
                            },
                        },
                        payload,
                    );

                    let shouldLog = this.loggingVerbosity === 'verbose';
                    if (!shouldLog && this.loggingVerbosity === 'errors') {
                        shouldLog =
                            eventType === 'http_queue:request_error' ||
                            eventType === 'http_queue:retry' ||
                            (requestConfig.httpQueue && requestConfig.httpQueue.retry);
                    }

                    // Note: http_queue events were accounting for over 50% of our DB size
                    if (shouldLog) {
                        return EventLogger.log(eventType, payload, {
                            segmentio: false,

                            // In order to avoid creating an infinte loop where each event bundle
                            // request logs events, this requiring another request, we use the
                            // deferIndefinitely flag, which does not send these events until there
                            // are also other events to send
                            deferIndefinitely: true,
                        });
                        // if logging is disabled, simply create an event manually that is never sent
                        // this allows the rest of the logic in http_queue to work
                    }

                    const properties = angular.extend({}, payload);
                    return new Event(eventType, properties);
                },
            };
        });

        return HttpQueue;
    },
]);
