import 'EventLogger/angularModule';
import 'PrioritizedInterceptors/angularModule';
import 'FrontRoyalStore/angularModule';
import 'Iguana';

const HttpQueueAndRetry = angular.module('HttpQueueAndRetry', [
    'prioritizedInterceptors',
    'SuperModel',
    'EventLogger',
    'FrontRoyalStore',
]);
export default HttpQueueAndRetry;

// See HttpQueue for more docs on what this does

/*
    Add an http interceptor that will send all requests that should
    be queued through the HttpQueue
*/
HttpQueueAndRetry.config([
    'PrioritizedInterceptorsProvider',
    PrioritizedInterceptorsProvider => {
        // Use a low priority so that this will be run before $auth request interceptor
        PrioritizedInterceptorsProvider.addInterceptor(-100, [
            '$injector',

            $injector => {
                const HttpQueue = $injector.get('HttpQueue');
                // eslint-disable-next-line no-shadow
                const HttpQueueAndRetry = $injector.get('HttpQueueAndRetry');

                return {
                    request(config) {
                        if (HttpQueueAndRetry.shouldQueue(config)) {
                            const promise = HttpQueue.queue(config);
                            return promise;
                        }
                        return config;
                    },
                    response(response) {
                        return HttpQueue.onResponseSuccess(response);
                    },
                    responseError(response) {
                        return HttpQueue.onResponseError(response);
                    },
                };
            },
        ]);
    },
]);

/*
    The HttpQueueAndRetry service provides configuration methods
    that allow for setting up HttpQueueAndRetry (for now, the only
    configuration is defining which requests should be queued)
*/
HttpQueueAndRetry.factory('HttpQueueAndRetry', [
    '$injector',
    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const Singleton = $injector.get('Singleton');
        const frontRoyalStore = $injector.get('frontRoyalStore');

        // eslint-disable-next-line no-shadow
        const HttpQueueAndRetry = SuperModel.subclass(function () {
            this.include(Singleton);
            this.defineSingletonProperty('shouldQueue', 'addFilter');

            return {
                initialize() {
                    this._filters = [];
                },

                addFilter(filterFunc) {
                    this._filters.push(filterFunc);
                },

                shouldQueue(requestConfig) {
                    if (
                        // Why the elvis operator? In tests, we sometimes mock out frontRoyalStore,
                        // which can surprisingly break template loading and other things in ways
                        // that our not easy to track back to here.
                        frontRoyalStore.treatAsBackgroundRequest?.(requestConfig)
                    ) {
                        return false;
                    }

                    if (requestConfig?.httpQueueOptions?.shouldQueue) {
                        return true;
                    }

                    if (requestConfig?.httpQueueOptions?.shouldQueue === false) {
                        return false;
                    }

                    if (this._filters.length === 0) {
                        return true;
                    }
                    return this._filters.some(filter => filter(requestConfig));
                },
            };
        });

        return HttpQueueAndRetry;
    },
]);
