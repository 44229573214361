import AppBrandConfigs from './AppBrandConfigs';
import { type BrandConfig, type BrandOrInstitutionId } from './AppBranding.types';

export default function fetchBrandConfig(brandOrInstitutionId: BrandOrInstitutionId): BrandConfig {
    const result = AppBrandConfigs[brandOrInstitutionId];
    if (!result) {
        throw new Error(`No AppBrandConfig for key ${brandOrInstitutionId}`);
    }
    return result;
}
