import { createApi } from '@reduxjs/toolkit/query/react';
import { type AnyObject } from '@Types';
import { setAuthHeaders } from 'ReduxHelpers';
import { buildBaseBackRoyalQueryFn, keepUnusedDataFor } from './buildBaseBackRoyalQueryFn';

export const backRoyalApi = createApi({
    reducerPath: 'BackRoyalApi',
    baseQuery: buildBaseBackRoyalQueryFn('BackRoyalApi'),
    keepUnusedDataFor,
    refetchOnMountOrArgChange: process.env.STORYBOOK === 'true',
    endpoints: builder => ({
        // dynamic endpoint to be used for one off network requests, such as a request triggered from EventLogger
        dynamicEndpoint: builder.query<unknown, { input: RequestInfo; init: RequestInit }>({
            queryFn: async ({ input, init }, { signal }) => {
                const updatedInit = { ...init, headers: setAuthHeaders(new Headers(init.headers)), signal };

                try {
                    const response = await fetch(input, updatedInit);
                    let data;

                    try {
                        data = JSON.parse(await response.text());
                    } catch {
                        data = undefined;
                    }

                    if (response.ok) return { data };

                    return { error: { status: response.status, data } };
                } catch (e) {
                    const error = {
                        status: (e as { status: number })?.status ?? 500,
                        data: JSON.stringify(e),
                    };

                    return { error };
                }
            },
        }),

        autoSuggestOptions: builder.query<unknown, { filters: AnyObject; limit?: number }>({
            query: ({ filters = {}, limit = 10 }) => ({
                url: 'auto_suggest_options.json',
                params: {
                    ...Object.entries(filters).reduce(
                        (acc, [key, value]) => ({ ...acc, [`filters[${key}]`]: value }),
                        {},
                    ),
                    limit,
                },
            }),
            transformResponse: (response: { contents: unknown }) => response.contents,
        }),
    }),
});

export default backRoyalApi;
