import 'angular-cookie';

import 'Injector/angularModule';
import Auid from 'Auid';
import 'DateHelper/angularModule';
import ClientStorage from 'ClientStorage';
import 'EventLogger/angularModule';
import 'angular-moment';

/*-----------------------------------------------------------------------------------*/
/*  Angular App for Event Logging
/*-----------------------------------------------------------------------------------*/

(angular => {
    // create the application module
    const app = angular
        .module('OuterScreens', [
            'EventLogger',
            'Iguana',
            'Iguana.Adapters.RestfulIdStyle',
            'DateHelper',
            'Injector',
            'angularMoment',
        ])

        .config([
            '$injector',
            'IguanaProvider',
            '$compileProvider',
            ($injector, IguanaProvider, $compileProvider) => {
                const uri = `${window.ENDPOINT_ROOT}/api`;
                IguanaProvider.setAdapter('Iguana.Adapters.RestfulIdStyle');
                IguanaProvider.setBaseUrl(uri);

                // by default, time requests out after 30 seconds
                IguanaProvider.setDefaultRequestOptions({
                    timeout: 30 * 1000,
                });

                // allow for sanitization of javascript, etc
                $compileProvider.aHrefSanitizationTrustedUrlList(/^\s*(https?|mailto|blob|data|javascript*):/);

                // Set locale for moment (we only support English on marketing pages)
                $injector.get('moment').updateLocale('en');
            },
        ]);

    app.controller('OuterScreensCtrl', [
        '$injector',
        // eslint-disable-next-line func-names
        function ($injector) {
            const $window = $injector.get('$window');
            const EventLogger = $injector.get('EventLogger');
            const $rootScope = $injector.get('$rootScope');

            this.continueApplicationInMarketing = ClientStorage.getItem('continueApplicationInMarketing') === 'true';

            this.dateHelper = $injector.get('dateHelper');

            this.logClick = identifier => {
                EventLogger.log(
                    'click',
                    {
                        label: `marketing:${identifier}`,
                    },
                    {
                        segmentioType: 'marketing:click',
                        segmentioLabel: identifier,
                    },
                );
            };

            this.loggedIn = $window.hasAuthCookie;

            if (!this.loggedIn) {
                Auid.ensure($rootScope.currentUser);
            }

            const auid = Auid.get($rootScope.currentUser);
            if (auid) {
                window.analytics?.identify(auid, undefined, {
                    integrations: {
                        'Customer.io': false,
                    },
                });
            }
        },
    ]);

    app.run([
        '$injector',
        $injector => {
            const $http = $injector.get('$http');
            const $q = $injector.get('$q');
            const EventLogger = $injector.get('EventLogger');
            const $window = $injector.get('$window');

            // log the page_load
            EventLogger.logStartEvent();
            EventLogger.trackLocationChanges();

            // remove buffering because
            // - onload handler is unreliable
            // - user switch pages more often on marketing pages
            // - we don't log lots of events from marketing pages
            //
            // Do this after logging the page_load event and the
            //  pageview event so at least those two get bundled
            EventLogger.sendEventsImmediately();

            // either use pre-loaded config, or load config as necessary
            if (window.preloadedConfig) {
                $q.when(window.preloadedConfig);
            } else {
                $http.get(`${$window.ENDPOINT_ROOT}/api/config.json`).then(response => {
                    if (
                        response &&
                        response.data &&
                        response.data.contents &&
                        response.data.contents.config &&
                        response.data.contents.config[0]
                    ) {
                        return response.data.contents.config[0];
                    }
                    return $q.reject('invalid config response');
                });
            }
        },
    ]);
})(angular);
