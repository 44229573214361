import { type BaseQueryApi, type FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { type BaseFetchQueryFn, type RTKQueryError } from 'ReduxHelpers';

function formatFetchError(error: FetchBaseQueryError): FetchBaseQueryError {
    if (error.status !== 'FETCH_ERROR') return error;
    const baseQueryError = error as FetchBaseQueryError;
    baseQueryError.data = {
        message: 'A network error occurred.',
        meta: {},
    };
    baseQueryError.status = 0;
    return baseQueryError;
}

function formatParseError(error: FetchBaseQueryError): FetchBaseQueryError {
    if (error.status !== 'PARSING_ERROR') return error;
    const originalStatus = error.originalStatus;

    // If the original request was not an error, then the parse error is the real problem. If the
    // original request was an error, then we're more interested in the original status code then
    // we are in the fact that the body of the response couldn't be parsed.
    if (originalStatus < 400) return error;

    const baseQueryError = error as FetchBaseQueryError;
    baseQueryError.data = {
        message: 'An unknown error occurred.',
        meta: {},
    };
    baseQueryError.status = originalStatus;
    return baseQueryError;
}

function addEndpointPath(error: FetchBaseQueryError, apiName: string, api: BaseQueryApi): RTKQueryError {
    const returnedError = error as RTKQueryError;
    returnedError.endpointPath = `${apiName}/${api.endpoint}`;
    return returnedError;
}

function formatError(error: FetchBaseQueryError, apiName: string, api: BaseQueryApi): FetchBaseQueryError {
    error = formatFetchError(error);
    error = formatParseError(error);
    error = addEndpointPath(error, apiName, api);
    return error;
}

// RTKQuery handles AbortError specially, so the error formatted by formatErrorResponse will
// not bubble up.
export function formatErrorResponse({
    apiName,
    query,
}: {
    apiName: string;
    query: BaseFetchQueryFn<unknown>;
}): BaseFetchQueryFn<unknown> {
    return async (args, api, extraOptions = {}) => {
        const result = await query(args, api, extraOptions);

        if (result.error) {
            result.error = formatError(result.error, apiName, api);
        }

        return result;
    };
}
