/* eslint-disable func-names */

angular.module('AClassAbove').factory('AClassAbove.Convenience', [
    function () {
        return {
            classMixin: {
                inheritsFrom(klass) {
                    return this === klass || this.ancestors.indexOf(klass) > -1;
                },
            },

            instanceMixin: {
                isA(klass) {
                    return this.constructor.inheritsFrom(klass);
                },
            },
        };
    },
]);
