import { gsap } from 'FrontRoyalGsap';

/*
 *  Accordion functions
 */
(() => {
    const accordionHeader = '.accordion__header';
    const accordionContent = '.accordion__content';
    const openClass = 'accordion--is-open';

    const openAccordionTL = gsap.timeline({
        paused: true
    });

    const closeAccordionTL = gsap.timeline({
        paused: true
    });

    function toggleAccordion(){
        const accordion = $(this).parent();
        const content = accordion.find(accordionContent);
        const otherAccordions = $(accordionHeader).parent().not($(this).parent());
        const otherAccordionsContent = otherAccordions.find(accordionContent);
        const contentHeight = content.children().first().outerHeight();

        function closeOtherAccordions(){
            $.each(otherAccordions, function(){
                if($(this).hasClass(openClass)){
                    closeAccordion(otherAccordionsContent);
                }
            });
        }

        if(accordion.hasClass(openClass)){
            closeAccordion(content);
        } else {
            closeOtherAccordions();
            openAccordion(content, contentHeight);
        }

        otherAccordions.removeClass(openClass);
        accordion.toggleClass(openClass);
    }

    function openAccordion(el, elHeight){
        openAccordionTL
        .to(el, {
            duration: 0,
            'max-height' : elHeight
        });

        openAccordionTL.play();
    }

    function closeAccordion(el){
        closeAccordionTL
        .to(el, {
            duration: 0,
            'max-height' : 0
        });

        closeAccordionTL.play();
    }

    $(accordionHeader).click(toggleAccordion);
})();
