import { type Brand, type BrandNameConfig } from './AppBranding.types';

const QuanticConfig: BrandNameConfig = {
    brandNameAbbr: 'Q',
    brandNameShort: 'Quantic',
    brandNameStandard: 'Quantic',
    brandNameLong: 'Quantic School of Business and Technology',
};

const ValarConfig: BrandNameConfig = {
    brandNameAbbr: 'V',
    brandNameShort: 'Valar',
    brandNameStandard: 'Valar Institute',
    brandNameLong: 'Valar Institute at Quantic School of Business and Technology',
};

const SmartlyConfig: BrandNameConfig = {
    brandNameAbbr: 'S',
    brandNameShort: 'Smartly',
    brandNameStandard: 'Smartly Institute',
    brandNameLong: 'Smartly Institute',
};

const MiyaMiyaConfig: BrandNameConfig = {
    brandNameAbbr: 'مية مية',
    brandNameShort: 'مية مية',
    brandNameStandard: 'مية مية',
    brandNameLong: 'مية مية',
};

const BrandNameConfigs: Record<Brand, BrandNameConfig> = {
    quantic: QuanticConfig,
    valar: ValarConfig,
    smartly: SmartlyConfig,
    miyamiya: MiyaMiyaConfig,
};

export default BrandNameConfigs;
