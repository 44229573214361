import { type Brand, type BrandNameConfig } from './AppBranding.types';
import BrandNameConfigs from './BrandNameConfigs';

export default function fetchBrandNameConfig(brand: Brand): BrandNameConfig {
    const result = BrandNameConfigs[brand];
    if (!result) {
        throw new Error(`No BrandNameConfig for key ${brand}`);
    }
    return result;
}
