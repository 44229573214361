import { type IScope, type ILocationService } from 'angular';
import { type FrontRoyalRootScope } from 'FrontRoyalAngular';
import { type CurrentUserIguanaObject } from 'Users';
import { targetBrand } from './targetBrand';
import { Brand, type BrandConfig, type Config } from './AppBranding.types';
import { getBrandName } from './BrandNameHelpers';
import fetchBrandConfig from './fetchBrandConfig';

/*
    This module contains exported functions that can be used to make it easier for our angular
    directives to display the appropriate brand name/logo in the UI.

    NOTE: This mixin assumes that a `currentUser` property will be defined on the scope; however,
        it's not necessary for the mixin to function.
*/
interface Scope extends IScope {
    currentUser?: CurrentUserIguanaObject;
    brandStyleClass: string;
}

interface OptsWithConfigAndBranding {
    config?: Config;
    branding?: Brand | (() => Brand | undefined);
}

/*
 *  Adds several properties to the scope so you an easily display the appropriate
 *  brand name in the UI. The properties that are added are as follows:
 *      `brandNameAbbr` - Returns the abbreviated version of the brand name.
 *      `brandNameShort` - Returns the shortened version of the brand name.
 *      `brandNameStandard` - Returns the standard version of the brand name.
 *      `brandNameLong` - Returns the long version of the brand name.
 *  @param `config` - App config that will be used as a fallback to determine the
 *      appropriate brand name values if no `currentUser` is found on the scope.
 */
export function setupBrandNameProperties(
    $injector: angular.auto.IInjectorService,
    scope: Scope,
    opts: OptsWithConfigAndBranding = {},
): void {
    ['abbr', 'short', 'standard', 'long'].forEach(format => {
        const prop = `brandName${format.charAt(0).toUpperCase()}${format.slice(1)}`;
        Object.defineProperty(scope, prop, {
            get() {
                const branding = getBrandingForSetupFunction(scope, opts, $injector);
                return getBrandName(branding, opts.config, format);
            },
        });
    });
}

/*
 *  Adds an arbitrary number of properties to the scope for convenient access.
 *  These names of the given properties should map to properties on BrandConfigs
 *  (see AppBrandConfigs.ts for a list of supported properties). When the scope
 *  property is referenced, the returned value will be in the context of the
 *  current user's target brand.
 *  @param `props` - An array of property names that map to BrandConfig properties.
 *  @param `config` - App config that will be used as a fallback to determine the
 *      appropriate value to return if no `currentUser` is found on the scope.
 */
export function setupBrandScopeProperties(
    $injector: angular.auto.IInjectorService,
    scope: Scope,
    props: (keyof BrandConfig)[],
    opts: OptsWithConfigAndBranding = {},
): void {
    props.forEach(prop => {
        Object.defineProperty(scope, prop, {
            get() {
                const branding = getBrandingForSetupFunction(scope, opts, $injector);
                return fetchBrandConfig(branding)[prop];
            },
            configurable: true,
        });
    });
}

/*
 *  Sets a property on the scope called `brandStyleClass` that's useful in the view
 *  in conjunction with the `ngClass` directive when you want to customize the styling
 *  of a specific part of the UI depending on whether the target brand for the user.
 *  @param `config` - App config that will be used as a fallback to determine the
 *      appropriate value to return if no `currentUser` is found on the scope.
 */
export function setupStyleHelpers(
    $injector: angular.auto.IInjectorService,
    scope: Scope,
    opts: OptsWithConfigAndBranding = {},
): void {
    scope.$watch(
        () => getBrandingForSetupFunction(scope, opts, $injector),
        () => {
            const branding = getBrandingForSetupFunction(scope, opts, $injector);
            scope.brandStyleClass = fetchBrandConfig(branding).brandStyleClass;
        },
    );
}

function getBrandingForSetupFunction(
    scope: Scope,
    opts: OptsWithConfigAndBranding,
    $injector: angular.auto.IInjectorService,
) {
    const currentUser = scope.currentUser || $injector.get<FrontRoyalRootScope>('$rootScope').currentUser;
    const $location = $injector.get<ILocationService>('$location');
    const { config } = opts;
    const brandingFromOpts = opts.branding;

    if (currentUser) {
        // unset the query param if logged in
        $location.search('institution', null);
    }

    let branding: Brand | undefined;
    if (!currentUser && Object.values(Brand).includes($location.search().institution)) {
        // logged out with ?institution= query param
        branding = $location.search().institution;
    } else if (typeof brandingFromOpts === 'function') {
        branding = brandingFromOpts();
    } else {
        branding = brandingFromOpts;
    }
    return branding || targetBrand(currentUser!, config);
}
