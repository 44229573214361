import { fetchBaseQuery as buildFetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';
import { type AnyObject } from '@Types';
import { setAuthHeaders, type BaseFetchQueryFn, type BaseFetchQueryFnArgs } from 'ReduxHelpers';
import { convertQueryCasing, snakeCaseApiParams } from './convertCasing';
import { retryNetworkErrors } from './retryNetworkErrors';
import { formatErrorResponse } from './formatErrorResponse';

export const baseUrl = `${window.ENDPOINT_ROOT}/api`;
export const keepUnusedDataFor = process.env.NODE_ENV !== 'test' ? 60 : 0;

const serializeParams = (params: AnyObject) => {
    const snakeCasedParams = snakeCaseApiParams(params);

    // When converting to a querystring, we can't have a nested object. Any value at the
    // top level that is an object should be converted to json. So, for example, if the params
    // are `{filters: {some: 'filters}}`, then we want to result to be filters={"some":"filters"}
    // For an example of where this is used, see LessonsApi#getPublishedStream
    const flattenedParams = Object.keys(snakeCasedParams).reduce<AnyObject>((acc, key) => {
        const val = snakeCasedParams[key];
        if (val && typeof val === 'object' && !Array.isArray(val)) {
            acc[key] = JSON.stringify(val);
        } else {
            acc[key] = val;
        }
        return acc;
    }, {});
    return qs.stringify(flattenedParams);
};

export function buildBaseBackRoyalQueryFn(
    apiName: string,
    { timeout = 30000 }: { timeout?: number } = {},
): BaseFetchQueryFn<unknown> {
    // setup base configuration
    const configuredQuery: BaseFetchQueryFn<unknown> = buildFetchBaseQuery({
        baseUrl,
        prepareHeaders: setAuthHeaders,
        paramsSerializer: serializeParams,
        timeout,
    });

    // camelcase the arguments going into the query and the result coming out
    const queryWithConvertCasing: BaseFetchQueryFn<unknown> = (args, api, extraOptions = {}) =>
        convertQueryCasing(args, (convertedArgs: BaseFetchQueryFnArgs) =>
            configuredQuery(convertedArgs, api, extraOptions),
        );

    // format errors so that fetch errors and parse errors are in the same format as errors from
    // our api (as much as possible)
    const queryWithFormattedErrors: BaseFetchQueryFn<unknown> = formatErrorResponse({
        apiName,
        query: (args, api, extraOptions) => queryWithConvertCasing(args, api, extraOptions),
    });

    // implement retry logic. This has to come after formatErrorResponse, because it requires the
    // formatted error
    const queryWithRetry: BaseFetchQueryFn<unknown> = retryNetworkErrors((args, api, extraOptions) =>
        queryWithFormattedErrors(args, api, extraOptions),
    );

    return queryWithRetry;
}
