/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable func-names */
(() => {
    const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
    const isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;

    if (isSafari) {
        $('body').addClass('safari');
    }

    if (isFirefox) {
        $('body').addClass('firefox');
    }

    // Detects Scroll on Hash Links to animate scrolling
    if ($('body').hasClass('home')) {
        $('a[href*="#"]:not([href="#"])').click(function () {
            if (
                location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
                location.hostname === this.hostname
            ) {
                let target = $(this.hash);
                target = target.length ? target : $(`[name=${this.hash.slice(1)}]`);
                if (target.length) {
                    $('html,body').animate(
                        {
                            scrollTop: target.offset().top,
                        },
                        750,
                    );
                    return false;
                }
            }
        });
    }
})();
