import { retry } from '@reduxjs/toolkit/query/react';
import { isRetriableNetworkError, type BaseFetchQueryFn } from 'ReduxHelpers';

export function retryNetworkErrors(query: BaseFetchQueryFn<unknown>) {
    return retry(
        async (args, api, extraOptions = {}) => {
            const result = await query(args, api, extraOptions);

            if (result.error && !isRetriableNetworkError(result.error)) {
                retry.fail(result.error);
            }

            return result;
        },
        {
            maxRetries: 1,
        },
    );
}
