/* eslint-disable func-names,prefer-arrow-callback */

angular.module('AClassAbove', []).provider('AClassAbove', function () {
    this.$get = [
        'Prototype.Class',
        'AClassAbove.ExtendableEnumerables',
        'AClassAbove.Convenience',
        function (Class, ...plugins) {
            const AClassAbove = Class.create();

            function extend(obj) {
                angular.forEach(
                    obj,
                    function (value, name) {
                        this.addInheritableProperties(name);
                        this[name] = value;
                    }.bind(this),
                );
            }

            function addInheritableProperties(...properties) {
                angular.forEach(
                    properties,
                    function (name) {
                        // eslint-disable-next-line no-prototype-builtins
                        if (this.hasOwnProperty(name)) {
                            return;
                        }
                        this._inheritableClassProperties.push(name);
                        const localName = `___${name}`;

                        Object.defineProperty(this, name, {
                            // eslint-disable-next-line getter-return,consistent-return
                            get() {
                                // eslint-disable-next-line no-prototype-builtins
                                if (this.hasOwnProperty(localName)) {
                                    return this[localName];
                                }
                                if (this.superclass) {
                                    return this.superclass[name];
                                }
                            },
                            set(val) {
                                this[localName] = val;
                            },
                            configurable: true,
                        });
                    }.bind(this),
                );

                angular.forEach(this.subclasses, function (subclass) {
                    subclass.addInheritableProperties(properties);
                });
            }

            AClassAbove._inheritableClassProperties = [];
            AClassAbove.extend = extend;
            AClassAbove.addInheritableProperties = addInheritableProperties;
            AClassAbove.ancestors = [];
            AClassAbove.extend({
                subclass(options) {
                    let initFunction;
                    if (!options) options = {};
                    if (options.constructor === Function) {
                        initFunction = options;
                        options = {};
                    }

                    const subclass = Class.create(this, options);
                    subclass.extend = extend;
                    subclass._inheritableClassProperties = [];
                    subclass.addInheritableProperties = addInheritableProperties;
                    // eslint-disable-next-line prefer-spread
                    subclass.addInheritableProperties.apply(subclass, this._inheritableClassProperties);
                    subclass.ancestors = this.ancestors.concat([this]);
                    if (initFunction) {
                        const instanceMixin = initFunction.apply(subclass) || {};
                        subclass.addMethods(instanceMixin);
                    }

                    return subclass;
                },

                include(options) {
                    this.addMethods(options);
                },
            });

            angular.forEach(plugins, function (mixins) {
                AClassAbove.extend(mixins.classMixin || {});
                AClassAbove.include(mixins.instanceMixin || {});
            });

            return angular.extend(AClassAbove, AClassAbove.classMixin);
        },
    ];
});
