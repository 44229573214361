import { createContext, useContext, type ReactNode } from 'react';

export const AngularContext = createContext<angular.auto.IInjectorService | null>(null);

type AngularProviderProps = {
    injector: angular.auto.IInjectorService;
    children: ReactNode;
};

export function AngularProvider({ injector, children }: AngularProviderProps) {
    return <AngularContext.Provider value={injector}>{children}</AngularContext.Provider>;
}

export function useAngularContext() {
    const context = useContext(AngularContext);
    if (context === null) {
        throw new Error('useAngularContext must be used within an AngularProvider');
    }
    return context;
}
