/* eslint-disable no-nested-ternary */
import { InstitutionId } from 'Institutions';
import AppBrandConfigs from './AppBrandConfigs';
import { Brand, type Config, type UserOrDomainOrBrand } from './AppBranding.types';

/*
    If `userOrDomainOrBrand` is null or undefined, we will need
    `config` in order to correctly fall back to the correct branding.  In practice,
    this means that if you're passing in `scope.currentUser` in a context where `currentUser`
    could possibly be undefined, then you HAVE to pass in `config`.  If you know `currentUser`
    will be defined, then you don't have to pass in `config.

    This isn't enforced anywhere though.  It would be nice to log to sentry if we
    ever call this method with neither argument set, but getting access to
    the error log service is hard here right now.
*/
export function targetBrand(userOrDomainOrBrand: UserOrDomainOrBrand, config?: Config | null): Brand {
    // sometimes it is convenient to just pass a brand into here.  If that
    // is the case, then just return it right back out
    const brand = userOrDomainOrBrand as Brand;
    if (brand in AppBrandConfigs) return brand;

    const userOrDomain = userOrDomainOrBrand;
    if (typeof userOrDomain === 'string') {
        const domain = userOrDomain;
        if (domain.includes('valar')) return Brand.valar;

        if (domain.includes('quantic')) return Brand.quantic;

        // Falling back to Smartly for now to match the logic below.
        return Brand.smartly;
    }

    // Just above this is where we handle the case where the method is given
    // a domain instead of a user, so if we've made it this far, then we know
    // that a user has been given.
    const user = userOrDomain;

    // We would rather use getActiveInstitution from BaseUser.ts
    // but that causes a mess of circular dependencies that would need to be resolved
    const activeInstitution = user
        ? 'active_institution' in user
            ? user.active_institution
            : user.activeInstitution
        : null;

    // Sometimes you may want to force the Miya Miya brand name to be returned.
    // In other contexts, you may only want to show the Miya Miya brand name
    // if the user is a Miya Miya user, falling back to a different brand name
    // if they're not a Miya Miya user.
    if (window.CORDOVA?.miyaMiya || activeInstitution?.id === InstitutionId.miya_miya) return Brand.miya_miya;

    // see comment at the top of this function
    if (!user && !config) return Brand.quantic;

    // If there's no user then we need to check the config for what branding we should use
    if (!user && config?.isQuantic()) return Brand.quantic;

    if (!user) return Brand.smartly;

    return activeInstitution?.branding || Brand.smartly;
}

export default targetBrand;
