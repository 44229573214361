import { gsap } from 'FrontRoyalGsap';

(() => {
    $.fn.serializeObject = function() {
        const o = {};
        const a = this.serializeArray();
        $.each(a, function() {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };

    // candidatesSignupForm and employersSignupForm definitions moved to vendor/front-royal/components/authentication/scripts/marketing_signup_form_dir.js

    const loginForm = new smForm({
        selector: '#login-form',
        submitCallback(data, onFormError) {
            console.log(data);
            setTimeout(() => {
                onFormError({
                    message: 'Username and Password are invalid',
                    invalidFields: ['email', 'password']
                });
            }, 300);
        }
    });

    const forgotPasswordForm = new smForm({
        selector: '#forgot-password-form',
        submitCallback(data, onFormError) {
            console.log(data);
            setTimeout(() => {
                const forgotPasswordTL = gsap.timeline({
                    paused: true
                });
                toHide = $('#forgot-password-form').parent();

                forgotPasswordTL
                    .set(toHide, {
                        opacity: 1
                    })
                    .set('#forgot-password-form-success', {
                        opacity: 0
                    })
                    .to(toHide, {
                        duration: 0.3,
                        opacity: 0,
                        display: 'none'
                    })
                    .to('#forgot-password-form-success', {
                        duration: 0.3,
                        opacity: 1,
                        display: 'block',
                    })
                    .play()
                // TODO: set the confirmation UI with card height animation
            }, 1000);
        }
    });

    const resetPasswordForm = smForm({
        selector: '#reset-password-form',
        submitCallback(data, onFormError) {
            console.log(data);
            setTimeout(() => {
                window.location.href = '/login?password_reset=true'
            }, 1000);
        }
    });
})();