import { buildBaseBackRoyalQueryFn, keepUnusedDataFor } from './buildBaseBackRoyalQueryFn';

// We tried to implement these defaults in a createBackRoyalApi function, but because of the complexity
// around typing makeRequest, it was easier to just do a baseConfig like this, even though it means
// one extra line of code when creating back royal apis.
export function getBackRoyalApiBaseConfig(apiName: string) {
    return {
        baseQuery: buildBaseBackRoyalQueryFn(apiName),
        keepUnusedDataFor,
        refetchOnMountOrArgChange: process.env.STORYBOOK === 'true',
    };
}
