import { type ProgramType } from 'Program';
import { type CohortSectionOffer } from 'CohortSectionOffer';
import { type PaymentSituation } from 'PaymentSituation';

export enum AdmissionOfferStatus {
    OfferedAdmission = 'offered_admission',
    AcceptedAdmissionOffer = 'accepted_admission_offer',
    DeclinedAdmissionOffer = 'declined_admission_offer',
}

interface upcomingCohortDateItem {
    startDate: number;
}

export interface AdmissionOffer {
    id: string;
    offeredAt: number;
    cohortId: string;
    cohortName: string;
    originalCohortId?: string;
    cohortSectionOffers: CohortSectionOffer[];
    status: AdmissionOfferStatus;
    // FIXME: deprecated, remove once all UMAs are reading payment situations from CohortSectionOffers
    // See also: https://trello.com/c/oioaNcW3/769-chore-read-payment-situations-from-cohortsectionoffers-not-admissionoffers
    selectablePaymentSituations: PaymentSituation[];
    programApplicationId: string;
    programType: ProgramType;
    cohortProgramGuideUrl: string;
    eligibleThreeYearDegree: boolean;
    reportsTags: string[];

    // The following properties are optional because they are only returned in an AdmissionOffer's JSON
    // when the AdmissionOffer's status is 'offered_admission'.
    // Ideally we would have a second type definition for this, similar to AssessmentBasedRefundEntitlementMetadata,
    // but that would require a hefty refactor.
    upcomingCohortDates?: upcomingCohortDateItem[];
    fastTrackRegistrationDeadline?: number | null;
    fastTrackStartDate?: number | null;
    fastTrackCohortTitle?: string | null;
    fastTrackProgramGuideUrl?: string | null;
    earlyRegistrationDeadline?: number | null;
}
