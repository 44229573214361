/* eslint-disable func-names */

angular.module('AClassAbove').factory('Singleton', [
    '$injector',

    function ($injector) {
        const AModuleAbove = $injector.get('AModuleAbove');

        return new AModuleAbove({
            included(Klass) {
                Object.defineProperty(Klass, 'instance', {
                    get() {
                        this._instance = this._instance || this.createInstance();
                        return this._instance;
                    },
                });
            },

            classMixin: {
                // may want to override this with custom
                // arguments for the initialize
                createInstance() {
                    return new this();
                },

                defineSingletonProperty(...args) {
                    args.forEach(meth => {
                        Object.defineProperty(this, meth, {
                            get() {
                                const val = this.instance[meth];
                                if (typeof val === 'function') {
                                    return val.bind(this.instance);
                                }
                                return val;
                            },
                            set() {
                                throw new Error(
                                    `${meth} cannot be set.  If you are trying to mock this in tests, mock it on the prototype`,
                                );
                            },
                        });
                    });
                },
            },
        });
    },
]);
