/* eslint-disable func-names,prefer-arrow-callback */

angular.module('AClassAbove').factory('AClassAbove.ExtendableEnumerables', [
    function () {
        return {
            classMixin: {
                extendableArray(name) {
                    const localName = `_local_${name}`;
                    const obj = {};
                    obj[name] = function () {
                        // eslint-disable-next-line no-prototype-builtins
                        if (!this.hasOwnProperty(localName)) {
                            this[localName] = [];
                        }
                        let val = [];
                        const local = this[localName];

                        if (this.superclass && this.superclass[name]) {
                            const superVal = this.superclass[name]();
                            val = val.concat(superVal);
                        }

                        val = val.concat(local);
                        val.push = function (...args) {
                            // eslint-disable-next-line prefer-spread
                            local.push.apply(local, args);
                        };
                        return val;
                    };

                    this.extend(obj);
                },

                extendableObject(name) {
                    const localName = `_local_${name}`;
                    const obj = {};
                    obj[name] = function () {
                        // eslint-disable-next-line no-prototype-builtins
                        if (!this.hasOwnProperty(localName)) {
                            this[localName] = {};
                        }
                        const local = this[localName];
                        let val = Object.create({
                            set(_name, value) {
                                local[_name] = value;
                            },
                        });

                        if (this.superclass && this.superclass[name]) {
                            const superVal = this.superclass[name]();
                            angular.extend(val, superVal);
                        }

                        val = angular.extend(val, local);
                        return val;
                    };

                    this.extend(obj);
                },
            },
        };
    },
]);
