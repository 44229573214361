import fetchBrandConfig from './fetchBrandConfig';
import { type Config, type UserOrDomainOrBrand, type BrandConfig } from './AppBranding.types';
import targetBrand from './targetBrand';

export function targetBrandConfig(user: UserOrDomainOrBrand, config?: Config | null): BrandConfig {
    const branding = targetBrand(user, config);
    return fetchBrandConfig(branding);
}

export default targetBrandConfig;
